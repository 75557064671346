<template>
    <v-card elevation="0">
        <v-card-text class="d-flex justify-center align-center fill-height" style="padding: 0;">
            <div class="zoom-container" ref="zoomContainer"
            @wheel.prevent="zoomImage"
            @mousedown="onMouseDown"
            @mousemove="onMouseMove"
            @mouseup="onMouseUp"
            @mouseleave="onMouseUp"
            @touchstart="onTouchStart"
            @touchmove="onTouchMove"
            @touchend="onTouchEnd"
            >
                <v-img :src="imagen" class="image" :style="{ transform: `translate(${translateX}px, ${translateY}px) scale(${scale})` }"  contain></v-img>
            </div>
        </v-card-text>
        <v-footer color="#ef6b01" padless fixed class="mt-5" style="height: 30px;" rounded="0"></v-footer>
    </v-card>
</template>

<script>
export default {
    name: 'ImagenZoom',
    props:{imagen: {type: String}},
    data() {
        return {
            scale: 1,
            startDistance: 0,
            startX: 0,
            startY: 0,
            translateX: 0,
            translateY: 0,
            lastTranslateX: 0,
            lastTranslateY: 0,
            isDragging: false,
        }
    },
    methods: {
        zoomImage(event) {
            const zoomFactor = 0.1;
            if (event.deltaY < 0) {
                this.scale += zoomFactor;
            } else if (event.deltaY > 0 && this.scale > 0.5) {
                this.scale -= zoomFactor;
            }
        },
        onTouchStart(e) {
            if (e.touches.length === 2) {
                const dx = e.touches[0].clientX - e.touches[1].clientX;
                const dy = e.touches[0].clientY - e.touches[1].clientY;
                this.startDistance = Math.sqrt(dx * dx + dy * dy);
            } else if (e.touches.length === 1) {
                this.startX = e.touches[0].clientX - this.lastTranslateX;
                this.startY = e.touches[0].clientY - this.lastTranslateY;
            }
        },
        onTouchMove(e) {
            if (e.touches.length === 2) {
                const dx = e.touches[0].clientX - e.touches[1].clientX;
                const dy = e.touches[0].clientY - e.touches[1].clientY;
                const newDistance = Math.sqrt(dx * dx + dy * dy);
                const zoomFactor = newDistance / this.startDistance;
                this.scale = Math.max(0.5, Math.min(this.scale * zoomFactor, 5));
                this.startDistance = newDistance;
            } else if (e.touches.length === 1) {
                this.translateX = e.touches[0].clientX - this.startX;
                this.translateY = e.touches[0].clientY - this.startY;
            }
        },
        onTouchEnd() {
            this.lastTranslateX = this.translateX;
            this.lastTranslateY = this.translateY;
        },
        onMouseDown(e) {
            this.isDragging = true;
            this.startX = e.clientX - this.lastTranslateX;
            this.startY = e.clientY - this.lastTranslateY;
        },
        onMouseMove(e) {
            if (this.isDragging) {
                this.translateX = e.clientX - this.startX;
                this.translateY = e.clientY - this.startY;
            }
        },
        onMouseUp() {
            if (this.isDragging) {
                this.lastTranslateX = this.translateX;
                this.lastTranslateY = this.translateY;
                this.isDragging = false;
            }
        },
    },
    watch:{
        imagen(){
            this.scale = 1
            this.startDistance = 0
            this.startX = 0
            this.startY = 0
            this.translateX = 0
            this.translateY = 0
            this.lastTranslateX = 0
            this.lastTranslateY = 0
            this.isDragging = false
        }
    }
}
</script>

<style scoped>
.zoom-container {
  overflow: hidden;
  cursor: grab;
  width: 100%;
  height: 80vh;
  touch-action: none;
}

.image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  transition: transform 0.2s ease-out;
}
</style>