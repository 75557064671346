<template>
  <div>
    <v-card elevation="0">
            <v-toolbar color="#ef6b01">
            <v-btn icon dark @click="atras"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
            <v-card-title style="color:white" class="text-truncate text-center">Elegí cómo pagar</v-card-title>
        </v-toolbar>
        <v-card-text class="text-center">
            <v-row style="justify-content: center;">
                <v-radio-group v-model="medioDePago" column style="min-width: 372px;">
                    <v-radio v-if="usuario && usuario.ctacorrentista" color="orange" value="Cuenta corriente" class="mt-5 mb-2">
                        <template v-slot:label>
                            <v-row class="ml-1" style="align-items: end;">
                               <v-icon color="orange" style="font-size:47px">mdi-currency-usd</v-icon> <p class="ml-3" style="font-size:20px">Cuenta Corriente</p>
                            </v-row>
                        </template>
                    </v-radio>
                    <v-divider style="margin-left: 87px;"></v-divider>
                    <v-radio @change="dialogOtrosMedios = true" color="orange" value="Otro medio de pago" class="mt-5 mb-2" off-icon="far fa-circle" on-icon="far fa-circle-dot">
                        <template v-slot:label>
                            <v-row class="ml-1" style="align-items: end;">
                               <v-icon color="orange" style="font-size:47px">mdi-cash-sync</v-icon> <p class="ml-3" style="font-size:20px">Otro medio de pago</p>
                            </v-row>
                        </template>
                    </v-radio>
                    <v-divider style="margin-left: 87px;"></v-divider>
                
                </v-radio-group>
            </v-row>
        </v-card-text>
        <!--FOOTER---------------->
        <v-footer color="#ef6b01" padless fixed outlined class="mt-5" style="height: 57px;">
            <v-row justify="center" no-gutters>
                <v-col cols="4" class="text-center">
                </v-col>
                <v-col cols="4" class="text-center">
                    <v-btn style="bottom: 23px;" fab color="#ef6b01" elevation="20" @click="irVentas" class="my-2"><font-awesome-icon style="font-size:25px;color: white" icon="plus"/></v-btn>
                </v-col>
                <v-col cols="4" class="text-end">
                    <v-btn small style="height: 54px;font-size: initial;" color="white" text elevation="0" :disabled="!medioDePago" @click="buscarClientesAsociados()">Siguiente<v-icon color="white">mdi-chevron-right</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-footer>
    </v-card>
    <!-- DIALOG CLIENTES ASOCIADOS -->
    <v-dialog  v-model="dialogClientesAsociados" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card loader-height="8px">
            <v-toolbar color="#ef6b01"> 
                <v-btn icon dark @click="cerrarDialogClientes"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
                <v-card-title justify="center" style="color:white" class="text-truncate text-center">CUIT</v-card-title>
            </v-toolbar>
            <v-row justify="center" no-gutters class="mt-5">
                <v-col class="text-center">
                    <h3 style="color:orange">MIS CUITS</h3>
                </v-col>
            </v-row>
            <div style="margin-inline: 15px;" class="mt-5 mb-12">
                <v-list two-line v-if="this.clientesAsociados.length>0" elevation="0">
                    <v-list-item-group v-model="clienteSelected" color="orange">
                        <template v-for="(item, index) in clientesAsociados">
                            <v-list-item  :key="item.COD_CLI" :value="item">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox
                                            :input-value="active"
                                            color="orange"
                                        ></v-checkbox>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                        <!-- <v-list-item-subtitle><span><b :style="(active)?'color:#E65100':'color:orange'" style="font-size:17px">{{item.RZ}}</b></span></v-list-item-subtitle>
                                        <v-list-item-subtitle class="mt-2" :style="(active)?'color:#E65100;font-size: large;':'font-size: initial'"><span><b>{{item.CUIT}}</b></span></v-list-item-subtitle> -->

                                        <b>{{item.NOM_FANTASIA}}</b>
                                        <v-list-item-subtitle><span><b>Código: </b><b :style="(active)?'color:#E65100':'color:orange'" style="font-size:17px">{{item.COD_CLI}}</b></span></v-list-item-subtitle>
                                        <v-list-item-subtitle><span><b>IVA: </b><b :style="(active)?'color:#E65100':'color:orange'" style="font-size:17px">{{item.IB_ACTIV}}</b></span></v-list-item-subtitle>
                                        <v-list-item-subtitle><span><b>Razón Social: </b><b :style="(active)?'color:#E65100':'color:orange'" style="font-size:17px">{{item.RZ}}</b></span></v-list-item-subtitle>
                                        <v-list-item-subtitle><span><b>CUIT: </b><b :style="(active)?'color:#E65100':'color:orange'" style="font-size:17px">{{item.CUIT}}</b></span></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                            <v-divider :key="index"></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
            </div>
            <!--FOOTER---------------->
            <v-footer color="#ef6b01" padless fixed outlined class="mt-5" style="height: 57px;">
                
                <v-row justify="center" no-gutters>
                    <v-col cols="4">
                    </v-col>
                    <v-col cols="4" class="text-center">
                        <v-btn style="bottom: 23px;" fab color="#ef6b01" elevation="20" @click="irVentas" class="my-2"><font-awesome-icon style="font-size:25px;color: white" icon="plus"/></v-btn>
                    </v-col>
                    <v-col cols="4" class="text-center">
                        <v-btn :disabled="!clienteSelected" small style="height: 54px;font-size: initial;" color="white" text elevation="0" @click="siguiente()">Siguiente<v-icon color="white">mdi-chevron-right</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-footer>
        </v-card>
    </v-dialog>
    <!-- DIALOG OTROS MEDIOS DE PAGO -->
    <v-dialog v-model="dialogOtrosMedios" max-width="500px" persistent>
        <v-card rounded="0" elevation="0">
            <v-toolbar color="#ef6b01">
                <v-btn icon dark @click="dialogOtrosMedios = false"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
                <v-card-title style="color:#ffff">Otro medio de pago</v-card-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="mt-3">
                <p style="font-size: larger;font-weight: 600;"><b style="color:orange">{{usuario.nombre}}</b> te enviaremos un link de pago por WhatsApp al celular <b style="color:orange">{{usuario.celular}}</b> una vez que finalices la compra</p>
            </v-card-text>
            <v-card-actions style="justify-content: end;">
                <v-btn color="orange" elevation="5" outlined @click="dialogOtrosMedios = false" >Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- ----CARGANDO------- -->
    <v-overlay :value="cargando">
        <v-progress-circular
            indeterminate
            size="70"
            width="7"
        ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import ApiServer from '../api';
import {mapState,mapActions} from 'vuex';
export default {
    name:'Pago',
    data(){
        return{
            medioDePago:null,
            clientesAsociados:[],
            dialogClientesAsociados:false,
            clienteSelected: null,
            cargando:false,
            dialogOtrosMedios:false,
            celCliente:null
            
        }
    },
    methods:{
        ...mapActions(['editDatosCompra']),
        atras(){
            this.$router.go(-1)
        },
        irVentas(){
            this.$router.push({name: 'Home'});  
        },
        async buscarClientesAsociados(){
            this.cargando = true
            try {
                let result = await ApiServer.getClientesAsociados(this.usuario.cod_cli)
                if(result.length>1){
                    this.clientesAsociados = result
                    this.dialogClientesAsociados = true
                    this.$router.push('#miscuits')
                }else{
                    this.clienteSelected = {COD_CLI:this.usuario.cod_cli}
                    this.siguiente()
                }
                this.cargando = false
            } catch (error) {
                this.cargando = false
                console.log(error);
            }
        },
        siguiente(){
            this.editDatosCompra(['codCli',this.clienteSelected.COD_CLI])
            this.editDatosCompra(['medioPago',this.medioDePago])
            this.$router.push({name: 'ConfirmarOrden'});  
        },
        cerrarDialogClientes(){
            this.$router.back();
        },
        // async obtenerCelCliente(){
        //     this.cargando = true
        //     try {
        //         let resp = await ApiServer.getAplicacionUsuario(this.usuario.userId)
        //         console.log(resp);
        //         if(resp.length>0){
        //             this.celCliente = resp[0].telefono
        //             this.dialogOtrosMedios = true
        //         }
        //         this.cargando = false
        //     } catch (error) {
        //         this.cargando = false
        //         console.log(error);
        //     }
        // }
        
    },
    async mounted(){
        try {
            await ApiServer.postUsoLog({
                   cod_cli: this.usuario.cod_cli,
                   usuario: this.usuario.nombreUsuario,
                   accion: 'ELEGI COMO PAGAR',
                   dato: ''
               })
        } catch (error) {
            console.log(error);
        }
    },
    computed:{
        ...mapState(['usuario'])
    },
    watch:{
        '$route.hash'(newHash,oldHash) {
            if(newHash === '#miscuits') {
                this.dialogClientesAsociados = true
            } else if (oldHash === '#miscuits'){
                this.dialogClientesAsociados = false
            }
        }
    }
}
</script>

<style>

</style>