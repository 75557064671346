<template>
    <div>
        <v-card :loading="(cargando)?'orange':null" loader-height="8px" elevation="0">
            <v-toolbar color="#ef6b01" rounded="0" elevation="5" class="fixed-bar">
                <v-btn icon dark @click="atras"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
                <v-card-title style="color:#ffff">{{this.$route.params.agrupador.toUpperCase()}}</v-card-title>
            </v-toolbar>
            <div class="ml-2 mr-2 mt-5 text-center" v-if="this.$route.params.agrupador=='favoritos'">
                <v-text-field autofocus type="search" v-model="busqueda" color="orange" prepend-inner-icon="mdi-magnify" 
                    label="Buscar Producto"
                    outlined
                    clearable
                    clear-icon="fas fa-times"
                    @keyup.enter="buscar"
                ></v-text-field>
            </div>
            <div style="margin-inline: 15px;" class="mb-12 mt-1" >
                <v-list three-line v-if="this.articulosFiltrados.length>0" >
                    <template v-for="(item, index) in articulosFiltrados" >
                        
                        <v-list-item  @change="(agregarCarrito==false)? clicArt(item): ''" :key="item.COD_ART" v-ripple="{ class: 'orange--text' }" >
                        
                        <v-list-item-avatar :rounded="true" size="80px" horizontal>
                            <Imagen v-if="item.imagen" :src="item.imagen" :src2="item.etiqueta"/>
                        </v-list-item-avatar>
                        

                        <v-list-item-content>
                            {{(item.DESC_CLIENTE)?item.DESC_CLIENTE:item.DESCRIPCION}}
                            <v-list-item-subtitle><span><b>Código: </b><b style="color:orange;font-size:17px">{{item.COD_ART}}</b></span></v-list-item-subtitle>
                            <v-list-item-subtitle><span><b>Empaque: </b><b style="color:orange;font-size:17px">{{(item.ESCALA!=item.ESC)?item.ESCALA:''}} {{item.CANT_EMPAQ}} {{item.ESC}}</b></span></v-list-item-subtitle>
                            <v-list-item-subtitle v-if="usuario.muestra_lista==1"><span><b>Precio contado: </b><b style="color:orange;font-size:17px">{{precioArt(item) | toCurrency}}</b></span><span v-if="precioConIva" style="color:orange;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                            <!-- <span style="font-size: 10px;text-decoration: line-through">{{Math.round(item.PR_VTA) | toCurrency}}</span> -->
                            <v-list-item-subtitle v-if="item.CANT_EMPAQ!=1 && usuario.muestra_lista==1"><span><b>Precio por empaque contado: </b><b style="color:orange;font-size:17px">{{precioPorEmpaque(item) | toCurrency}}</b></span><span v-if="precioConIva" style="color:orange;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                            <v-list-item-subtitle v-if="articulosCotizacion.find(x=>x.cod_art==item.COD_ART)" style="color: red;">Por más de {{ articulosCotizacion.find(x=>x.cod_art==item.COD_ART).cantidad }} {{ item.ESC_PLU.toLowerCase() }}, solicitar cotización </v-list-item-subtitle>
                            <v-row no-gutters>
                                <v-col :cols="(paltallaChica)?'12':'5'" v-if="item.pviejo && agrupador=='ofertas'" align-self="start" style="margin-top: 3px;">
                                    <template v-if="(carrito.findIndex(i => i.cod_art==item.COD_ART)==-1)">
                                        <v-chip v-if="item.pviejo && agrupador=='ofertas'" color="orange" dark small>Antes:<span style="text-decoration: line-through">${{ item.pviejo }}</span> </v-chip>
                                    </template>
                                    <template v-else>
                                        <v-chip v-if="item.pviejo && agrupador=='ofertas'" color="orange" dark small><span> Antes: </span> <br><span style="text-decoration: line-through">${{ item.pviejo }}</span> </v-chip>
                                    </template>
                                </v-col>
                                <v-col v-if="agrupador!='ofertas'">
                                    <v-btn  @click.stop="verDialogCotizar(item)" v-if="articulosCotizacion.find(x=>x.cod_art==item.COD_ART)" elevation="0" outlined small dark style="max-width: 100px;" color="orange"><font-awesome-icon style="font-size:20px" icon="dollar-sign"/>Cotizar</v-btn>
                                </v-col>
                                <v-col>
                                    <div style="justify-content: end; display: grid;">
                                        <v-btn v-if="(carrito.findIndex(i => i.cod_art==item.COD_ART)==-1)" color="orange" @click="agregarCarrito=true,agregar(item)" elevation="0" outlined small dark style="max-width: 100px;"><font-awesome-icon style="font-size:20px" icon="cart-shopping"/>{{(usuario.muestra_lista==1)?'Agregar':'Cotizar'}}</v-btn>
                                        <template v-if="(carrito.findIndex(i => i.cod_art==item.COD_ART)!=-1)">
                                            <p style="color:green;margin: 0px;margin-right:14px;margin-top: 3px;"><v-icon style="font-size:20px" color="green">mdi-cart-check</v-icon>Agregado al carrito</p>
                                            <v-row class="mt-3" style="margin: auto;">
                                                <v-btn color="orange" fab icon small @click="cantidadMenos(item)"><v-icon>mdi-minus-thick</v-icon></v-btn>
                                                <v-text-field
                                                v-model="carrito[carrito.findIndex(i => i.cod_art==item.COD_ART)].cantidad"
                                                label="Cantidad"
                                                type="number"
                                                dense
                                                flat
                                                solo
                                                hide-details="true"
                                                color="orange"
                                                style="max-width: 95px;align-items: center;"
                                                @click="verDialogCantidad(carrito[carrito.findIndex(i => i.cod_art==item.COD_ART)])"
                                                class="centered-input text-orange"
                                                readonly
                                                ></v-text-field>
                                                <v-btn color="orange" fab icon small @click="cantidadMas(item)"><v-icon>mdi-plus-thick</v-icon></v-btn>
                                            </v-row>
                                            <p style="color:orange;text-align: center;max-width: 168px;">{{descEmpaque(item)}}</p>
                                        </template>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                       

                        </v-list-item>
                        <v-divider :key="index" :inset="item.inset"></v-divider>
            
                    </template>
                </v-list>
                <template v-if="this.articulosFiltrados.length==0 && !this.cargando">
                    <p style="text-align: center; margin-top:50px" ><v-icon>mdi-information-outline</v-icon> No hay artículos {{this.$route.params.agrupador}}</p>
                </template>
            </div>
            <!--FOOTER---------------->
            <v-footer color="#ef6b01" padless fixed class="mt-5" style="justify-content: end" rounded="0">
            <v-row justify="center" no-gutters style="height: 45px;">
                <v-col cols="4">

                </v-col>
                <v-col cols="4" class="text-center">
                    <v-btn style="bottom: 23px;" fab color="#ef6b01" elevation="20" @click="irHome" class="my-2"><font-awesome-icon style="font-size:25px;color: white" icon="plus"/></v-btn>
                </v-col>
                <v-col cols="4" class="text-end">
                    <v-btn @click="irCarrito" icon class="my-1 mr-2" color="white"><font-awesome-icon style="font-size:22px;color: white;" icon="cart-shopping"/>{{(carrito.length>0)?carrito.length:''}}</v-btn>
                </v-col>
            </v-row>
        </v-footer>
        </v-card>
         <!-- DIALOG CANTIDAD -->
        <v-dialog v-model="dialogCantidad" max-width="300px" persistent>
            <v-card v-if="itemCantSelected">
                <v-toolbar color="#ef6b01">
                    <v-btn icon dark @click="cerrarDialogCambiarCantidad"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
                    <v-card-title style="color:#ffff">Cambiar cantidad</v-card-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="mt-3" v-if="itemCantSelected">
                    <v-text-field autofocus outlined style="font-size: 20px;" v-model="carrito[carrito.findIndex(i => i.cod_art==itemCantSelected.cod_art)].cantidad" label="Cantidad" type="number" solo color="orange"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-if="itemCantSelected" color="orange" elevation="5" outlined @click="cambiarCantidad()" :disabled="!validarCantidad">Aceptar</v-btn>
                    <v-btn color="grey" elevation="5" @click="cerrarDialogCambiarCantidad" outlined>Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- DIALOG COTIZAR ITEM -->
        <v-dialog v-model="dialogCotizarItem" max-width="300px" persistent>
            <v-card v-if="itemCotizar">
                <v-toolbar color="#ef6b01">
                    <v-btn icon dark @click="dialogCotizarItem"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
                    <v-card-title style="color:#ffff">Cantidad a cotizar</v-card-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="mt-3">
                    <v-text-field autofocus outlined style="font-size: 20px;" :min="articulosCotizacion.find(x=>x.cod_art==itemCotizar.COD_ART).cantidad" v-model="cantCotizarItem" label="Cantidad" type="number" solo color="orange"></v-text-field>
                    <p style="color:orange;text-align: center;max-width: 168px;">{{descEmpaque(itemCotizar,cantCotizarItem)}}</p>
                </v-card-text>
                <v-card-actions>
                    <v-btn  color="orange" elevation="5" outlined  :disabled="!cantCotizarItem || cantCotizarItem<articulosCotizacion.find(x=>x.cod_art==itemCotizar.COD_ART).cantidad" @click="cotizarItem">Aceptar</v-btn>
                    <v-btn color="grey" elevation="5" @click="dialogCotizarItem = false" outlined>Cerrar</v-btn>
                </v-card-actions>
            </v-card>
            <!-- ----CARGANDO------- -->
            <v-overlay :value="cargando">
                <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                indeterminate
                                size="70"
                                width="7"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-center">
                            <span>Generando cotización</span>
                        </v-col>
                    </v-row>
            </v-overlay>
            
        </v-dialog>
         <!-- ----CARGANDO------- -->
        <v-overlay :value="cargando">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
            ></v-progress-circular>
        </v-overlay>
        <!-- SNACKBAR -->
        <v-snackbar v-model="snackbar" :color="snackbarColor" text outlined class="mb-15">
            {{ snackbarMsge }}
            <template v-slot:action="{ attrs }">
                <v-btn icon :color="snackbarColor" text v-bind="attrs" @click="snackbar = false"><v-icon>{{snackbarIcon}}</v-icon></v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import ApiServer from '../api';
import {mapState,mapActions} from 'vuex'
import Imagen from '../components/Imagen.vue'
import moment from 'moment'
export default {
    name:'ListaDeArticulos',
    components:{Imagen},
    data(){
        return{
            articulos:[],
            articulosFiltrados:[],
            cargando:false,
            agregarCarrito:false,
            itemCantSelected: null,
            dialogCantidad: false,
            busqueda: null,
            cargandoArticulos: false,
            agrupador : null,
            articulosCotizacion:[],
            dialogCotizarItem:false,
            itemCotizar:null,
            cantCotizarItem: null,
            snackbar:false,
            snackbarColor:'error',
            snackbarMsge:'',
            snackbarIcon:'mdi-check-circle',
        }
    },
    methods:{
        ...mapActions(['agregarAlCarrito','editarCantCarrito','borrarArtCarrito']),
        async clicArt(item){
            try {
                await ApiServer.postUsoLog({
                   cod_cli: this.usuario.cod_cli,
                   usuario: this.usuario.nombreUsuario,
                   accion: `ARTICULO_${this.$route.params.agrupador.toUpperCase()}`,
                   dato: item.COD_ART
               })
                this.$router.push({name: 'Articulo', params: { codArt: item.COD_ART,datosArticulo:item }});
            } catch (error) {
                console.log(error);
            }
        },
        async agregar(item){
            this.cargando = true
            try {
                let result = await ApiServer.postCarritoCliente({
                    cod_cli:this.usuario.cod_cli,
                    cod_art:item.COD_ART,
                    cantidad: 1,
                    lpr_id:item.LISTA_ID,
                    usuario: this.usuario.nombreUsuario,
                    accion:`CARRITO_${this.$route.params.agrupador.toUpperCase()}`
                });
                this.agregarAlCarrito({
                    id:result.insertId,
                    lpr_id:item.LISTA_ID,
                    cod_art:item.COD_ART,
                    art_id:item.ART_ID,
                    desc_cliente: item.DESC_CLIENTE,
                    descripcion: item.DESCRIPCION,
                    mod:item.MOD,
                    med:item.MED,
                    desc_if:item.DESC_IF,
                    cantidad:1,
                    escala_id:item.ESCALA_ID,
                    escala:item.ESCALA,
                    esc:item.ESC,
                    esc_plu:item.ESC_PLU,
                    pr_vta:item.PR_VTA,
                    pr_cto_cpra:item.PR_CTO_CPRA,
                    pr_cto:item.PR_CTO,
                    pr_final:item.PR_FINAL,
                    descuento:item.DESCUENTO,
                    disponible:item.DISPONIBLE,
                    cant_empaq:item.CANT_EMPAQ,
                    ord_rec_str:item.ORD_REC_STR,
                    ean:item.EAN,
                    imagen : `https://loguin.com.ar/img/${item.ART_ID}.jpg?t=${(new Date()).getTime()}`,
                    etiqueta : item.etiqueta
                })
                this.cargando = false
            } catch (error) {
                this.cargando = false
                console.log(error);
            }
            setTimeout(()=>{
                this.agregarCarrito=false
            },1000)
          
        },
        async cantidadMas(item){
            this.agregarCarrito = true
            this.cargando = true
            try {
                let index = this.carrito.findIndex(i => i.cod_art==item.COD_ART)
                if((this.carrito[index].cantidad*this.carrito[index].cant_empaq)<item.DISPONIBLE){
                    let cant = this.carrito[index].cantidad+1
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                    this.cargando = false
                    setTimeout(()=>{
                        this.agregarCarrito=false
                    },2000)
                }else{
                    this.cargando = false
                    this.snackbar = true
                    this.snackbarColor ='error'
                    this.snackbarIcon = 'mdi-close-circle'
                    this.snackbarMsge='Sin stock'
                }
                
            } catch (error) {
                console.log(error);
                this.cargando = false
            }
        },
        async cantidadMenos(item){
            this.cargando = true
            this.agregarCarrito=true
            try {
                let index = this.carrito.findIndex(i => i.cod_art==item.COD_ART)
                if(index>=0){
                    if(this.carrito[index].cantidad>1){
                        let cant = this.carrito[index].cantidad-1
                        await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                        this.editarCantCarrito({index:index,cantidad:cant})
                    }else{
                        await ApiServer.deleteCarritoCliente(this.carrito[index].id)
                        this.borrarArtCarrito(this.carrito[index].id)
                    }
                }
                this.cargando = false
                setTimeout(()=>{
                    this.agregarCarrito=false
                },2000)
            } catch (error) {
                 console.log(error);
                this.cargando = false
            }
        },
        verDialogCantidad(item){
            this.agregarCarrito=true
            this.itemCantSelected = item
            this.dialogCantidad = true
            setTimeout(()=>{
                this.agregarCarrito=false
            },2000)
        },
        async cambiarCantidad(){
            this.dialogCantidad = false
            this.cargando = true
            let cant = Number(this.itemCantSelected.cantidad)
            try {
                if(cant>0 && (cant <= this.itemCantSelected.disponible)){
                    let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                }
                this.cargando = false
                this.itemCantSelected = null
            } catch (error) {
                this.cargando = false
                this.dialogCantidad = false
                this.itemCantSelected = null
                console.log(error);
            }
        },
        cerrarDialogCambiarCantidad(){
            let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
            if(index>=0){
                let cant = this.carrito[index].cantidad*this.carrito[index].cant_empaq
                if(cant>=this.itemCantSelected.disponible){
                    this.carrito[index].cantidad=this.itemCantSelected.disponible/this.itemCantSelected.cant_empaq   
                }
                if(cant<=0){
                    this.carrito[index].cantidad = 1
                }
                this.dialogCantidad = false
            }
            this.itemCantSelected = null
        },
        atras(){
            this.$router.go(-1)
        },
        irCarrito(){
            this.$router.push({name: 'Carrito'});
        },
        irHome(){
            this.$router.push({name: 'Home'});
        },
        descEmpaque(item,cant){
            if(!cant){
                cant = this.carrito[this.carrito.findIndex(i => i.cod_art==item.COD_ART)].cantidad
            }
            if(cant==1){
                if(item.ESCALA != item.ESC){
                    return `${cant} ${item.ESCALA} / ${cant*item.CANT_EMPAQ} ${item.ESC}`
                }else{
                    return `${cant} ${item.ESCALA}`
                }
            }else{
                if(item.ESCALA != item.ESC){
                    return `${cant} ${item.ESC_PLU} / ${cant*item.CANT_EMPAQ} ${item.ESC}`
                }else{
                    return `${cant} ${item.ESC_PLU}`
                }
            }
        },
        porcentajeIva(item){
            //IVA 10.5 o 21
            let x = item.PR_FINAL/item.PR_VTA
            if(Math.abs(21-(x-1)*100)<Math.abs(10.5-(x-1)*100)){
                return 21
            }else{
                return 10.5
            }
        },
        precioArt(item){
            let precio = null
            if(this.precioConIva){
                precio = item.PR_VTA + item.PR_VTA*(item.DESCUENTO/100)
            }else{
                precio = item.PR_FINAL + item.PR_FINAL*(item.DESCUENTO/100)
            }
            return Math.round(precio)
        },
        precioPorEmpaque(item){
            let precio = null
            if(this.precioConIva){
                let pr_vta= item.PR_VTA + item.PR_VTA*(item.DESCUENTO/100)
                precio = Math.round(pr_vta)*item.CANT_EMPAQ
            }else{
                let pr_final = item.PR_FINAL + item.PR_FINAL*(item.DESCUENTO/100)
                precio = Math.round(pr_final)*item.CANT_EMPAQ
            }
            return precio
        },
        verDialogCotizar(item){
            this.itemCotizar = item
            this.cantCotizarItem = this.articulosCotizacion.find(x=>x.cod_art==item.COD_ART).cantidad
            this.dialogCotizarItem = true
        },
        async cotizarItem(){
            try {
                this.cargando = true
                let cliente = []
                let result = await ApiServer.getDatosCliente(this.usuario.cod_cli)
                if(result.length>0){
                    let index = result.findIndex(x=>x.DOM_CLASIF_ID.trim()=='DE')
                    if(index>=0){
                        cliente = result[index]
                    }else{
                        cliente = result[0]
                    }
                }
                //TALONARIO
                let talonario = await ApiServer.getTalonarios(259);
                await ApiServer.putTalonarios(259)//TALONARIOS.NRO_CPR + 1
                let fecha = moment().format('DD-MM-YYYY')
                fecha = fecha.replace('-','.')
                fecha = fecha.replace('-','.')
                try {
                    let cpr_id = await ApiServer.getCprIdProcedure()
                    await ApiServer.postCpr({
                        id_pp:cpr_id.CPR_ID,
                        cpretc:11,
                        cprclasif:0,
                        cpr_tipo:"PR",
                        pv:talonario[0].NRO,
                        nro:talonario[0].NRO_CPR,
                        cpr_letra:"X",
                        tal_id:259,
                        fecha: fecha,
                        cli_id:this.usuario.cli_id,
                        rz:cliente.RZ,
                        cuit:cliente.CUIT,
                        iva_id:cliente.IVA_ID.trim(),
                        actividad:cliente.IB_ACTIV_ID,
                        dom:cliente.DOM,
                        loc_id:cliente.LOC_ID,
                        loc:cliente.LOCALIDAD,
                        cp:cliente.CP,
                        prov_id:cliente.PROVINCIA_ID,
                        tipo_pago_id:"CO",
                        lista_id:0,
                        lista:'LISTA BASE',
                        nota_cpr:"",
                        cond_id:'1',
                        computa_iva:(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI')?'S':'N',
                        discrimina_imp:(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI')?'S':'N'
                    })
                    //INSERTO CPRDET
                    try {
                        let MTOTAL = 0
                        let MTOTALN = 0
                        let IVA21 = 0
                        let IVA105 = 0
                        
                        let cprdet_id =await ApiServer.postCprDet({ //ME RETORNA EL CPRDET_ID
                            id_pp:cpr_id.CPR_ID,
                            art_id:this.itemCotizar.ART_ID,
                            cod_art:this.itemCotizar.COD_ART,
                            descripcion:this.itemCotizar.DESCRIPCION,
                            desc_cliente:this.itemCotizar.DESC_CLIENTE,
                            mod:this.itemCotizar.MOD,
                            med:this.itemCotizar.MED,
                            descif:this.itemCotizar.DESC_IF,
                            cant:(this.itemCotizar.CANT_EMPAQ==0)?(this.cantCotizarItem):(this.cantCotizarItem*this.itemCotizar.CANT_EMPAQ),
                            escala_id:this.itemCotizar.ESCALA_ID,
                            escala:this.itemCotizar.ESCALA,
                            lista_id:this.itemCotizar.LPR_ID,
                            pr_cto_cpra:this.itemCotizar.PR_CTO_CPRA,
                            pr_cto:this.itemCotizar.PR_CTO,
                            prfinal:this.itemCotizar.PR_FINAL,
                            pr_vta:this.itemCotizar.PR_VTA,
                            dep_id_org:33,
                            dep_id_dest:33,
                            porc_dr:0,
                            monto_dr:0
                        }) 
                        //INSERTO CPRDETIMP
                        let PRNETO = this.itemCotizar.PR_VTA
                        let PRFINAL = this.itemCotizar.PR_FINAL
                        let IMPU=0
                        let IMP_ID=0
                        if(this.itemCotizar.CANT_EMPAQ>1){
                            PRNETO = this.itemCotizar.PR_VTA*this.itemCotizar.CANT_EMPAQ
                            PRFINAL = this.itemCotizar.PR_FINAL*this.itemCotizar.CANT_EMPAQ
                        }
                        if(((PRFINAL/PRNETO)-1.21)<=0.01){
                            IVA21=IVA21+(PRNETO*.21*(this.cantCotizarItem));
                            IMPU=21;
                            IMP_ID=1;
                        }
                        if(((PRFINAL/PRNETO)-1.105)<=0.01){
                            IVA105=IVA105+(PRNETO*.105*(this.cantCotizarItem));
                            IMPU=10.5;
                            IMP_ID=2;
                        }
                        await ApiServer.postCprdetImp({
                            cprdet_id:cprdet_id.CPRDET_ID,
                            imp_id:IMP_ID,
                            impu:IMPU,
                            neto_mas_iva:PRNETO*this.cantCotizarItem,
                            monto_iva:PRNETO*(IMPU/100)*(this.cantCotizarItem)
                        })
                        //SUMO LOS TOTALES
                        MTOTAL=MTOTAL+(PRFINAL)*(this.cantCotizarItem);
                        MTOTALN=MTOTALN+(PRNETO)*(this.cantCotizarItem);

                        
                        //INSERTO EL PIE DEL COMPROBANTE
                        try {
                            await ApiServer.postCprpie({
                                cpr_id:cpr_id.CPR_ID,
                                impuesto_id:'-1',
                                porc:'0',
                                porcentaje:'0',
                                total_neto:MTOTALN,
                                total:MTOTAL
                            });
                            await ApiServer.postCprpie({
                                cpr_id:cpr_id.CPR_ID,
                                impuesto_id:'0',
                                porc:'0',
                                porcentaje:'0',
                                total_neto:MTOTALN,
                                total:MTOTAL
                            });
                            if(IVA21>=0.01){
                                await ApiServer.postCprpie({
                                cpr_id:cpr_id.CPR_ID,
                                impuesto_id:'1',
                                impuesto:'IVA 21%',
                                clasif_id:'1',
                                porc:'21',
                                porcentaje:'0',
                                total_neto:IVA21,
                                total:IVA21/1.21,
                                });
                            }
                            if(IVA105>=0.01){
                                await ApiServer.postCprpie({
                                cpr_id:cpr_id.CPR_ID,
                                impuesto_id:'2',
                                impuesto:'IVA 10.5%',
                                clasif_id:'1',
                                porc:'10.5',
                                porcentaje:'0',
                                total_neto:IVA105,
                                total:IVA105/1.105,
                                }); 
                            }
                            try {
                                await ApiServer.putCprMonto(cpr_id.CPR_ID,{monto:MTOTAL})
                                try {
                                    //CAMBIAR EL ESTADO A CPR_EST_ID=1
                                    await ApiServer.putCprEstado(cpr_id.CPR_ID,{estado:'1'})
                                } catch (error) {
                                    this.cargando=false
                                    console.log(error)
                                    this.snackbar=true
                                    this.snackbarMsge="Se produjo un error al cambiar estado"
                                    this.snackbarColor="error"
                                    this.snackbarIcon='mdi-close-circle'
                                }
                            } catch (error) {
                                this.cargando=false
                                console.log(error)
                                this.snackbar=true
                                this.snackbarMsge="Se produjo un error al actualizar monto"
                                this.snackbarColor="error"
                                this.snackbarIcon='mdi-close-circle' 
                            }
                        } catch (error) {
                            this.cargando=false
                            console.log(error)
                            this.snackbar=true
                            this.snackbarMsge="Se produjo un error al generar cprpie"
                            this.snackbarColor="error"
                            this.snackbarIcon='mdi-close-circle'
                        }
                    } catch (error) {
                        this.cargando=false
                        console.log(error)
                        this.snackbar=true
                        this.snackbarMsge="Se produjo un error al generar cprdet"
                        this.snackbarColor="error"
                        this.snackbarIcon='mdi-close-circle'
                    }
                    this.cargando=false
                    this.snackbar=true
                    this.snackbarMsge="Pedido procesado"
                    this.snackbarColor="success"
                    this.snackbarIcon='mdi-check-circle'
                    this.dialogCotizarItem = false
                    
                } catch (error) {
                    this.cargando=false
                    console.log(error)
                    this.snackbar=true
                    this.snackbarMsge="Se produjo un error al generar cpr"
                    this.snackbarColor="error"
                    this.snackbarIcon='mdi-close-circle'
                }
            } catch (error) {
                this.cargando=false
                console.log(error);
                this.snackbar = true
                this.snackbarColor ='error'
                this.snackbarIcon = 'mdi-close-circle'
                this.snackbarMsge='Se produjo un error'
            }
        }
    },
    async mounted(){
        if(!this.$route.params.agrupador){
            this.$router.push({name:'Home'})
        }else{
            this.agrupador = this.$route.params.agrupador
            this.cargando = true
            this.cargandoArticulos = true
            try {
                let resultArt = await ApiServer.getListaArticulos(this.usuario.cod_cli,this.$route.params.agrupador)
                let etiquetas = await ApiServer.getEtiquetasProductos()
                let result = await ApiServer.getProductosPorCotizacion(this.usuario.cod_cli)
                this.articulosCotizacion = result
                for(const art of resultArt){
                    
                    let resultDatosArt =  await ApiServer.getArtLpr(art.cod_art,this.usuario.listas[0].LISTA_ID,this.usuario.cli_id)
                    if(resultDatosArt && resultDatosArt.length>0 && resultDatosArt[0].DISPONIBLE>0){
                        resultDatosArt[0].imagen=`https://loguin.com.ar/img/${resultDatosArt[0].ART_ID}.jpg`
                        resultDatosArt[0].pviejo = art.pviejo
                        // if(resultDatosArt[0].DESCUENTO && resultDatosArt[0].DESCUENTO>0){
                        //     resultDatosArt[0].PR_FINAL = resultDatosArt[0].PR_FINAL - resultDatosArt[0].PR_FINAL * (resultDatosArt[0].DESCUENTO/100)
                        //     resultDatosArt[0].PR_VTA = resultDatosArt[0].PR_VTA - resultDatosArt[0].PR_VTA * (resultDatosArt[0].DESCUENTO/100)
                        // }
                        let indexEtiqueta = etiquetas.findIndex(x=>x.cod_art==art.cod_art)
                        if(indexEtiqueta>-1){
                            resultDatosArt[0].etiqueta = `https://loguin.com.ar/img/imag_etiquetas/${etiquetas[indexEtiqueta].etiqueta}?t=${(new Date()).getTime()}` 
                        }else{
                            resultDatosArt[0].etiqueta = null
                        }
                        this.articulos.push(resultDatosArt[0])
                        this.articulosFiltrados.push(resultDatosArt[0])
                        if(!this.busqueda)
                        this.cargando = false
                    }
                }
                
                this.cargandoArticulos = false
                if(this.cargando){
                    this.cargando = false
                }
                if(this.busqueda!=null && this.busqueda!=''){
                    let buscar = this.busqueda.toLowerCase()
                    this.articulosFiltrados = this.articulos.filter(function(item){
                        if(item.DESC_CLIENTE){
                            return (item.DESC_CLIENTE.toLowerCase().includes(buscar))
                        }else if(item.DESCRIPCION){
                            return (item.DESCRIPCION.toLowerCase().includes(buscar))
                        }else{
                            return false
                        }
                    }) 
                }
            } catch (error) {
                this.cargando = false
                this.cargandoArticulos = false
                console.log(error);
            }
        }
    },
    computed:{
        ...mapState(['usuario','carrito']),
        validarCantidad(){
            let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
            if(index>=0){
                let cant = this.carrito[index].cantidad*this.carrito[index].cant_empaq
                if(cant>this.itemCantSelected.disponible || cant<=0){
                    return false
                }else{
                    return true
                } 
            }
        },
        precioConIva(){
            if(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI'){
                return true
            }else{
                return false
            }
        },
        paltallaChica(){
            if(screen.width<420){
                return true
            }else{
                return false
            }
        }
    },
    watch:{
        busqueda(){
            if(!this.cargandoArticulos){
                if(this.busqueda == '' || this.busqueda==null){
                    this.articulosFiltrados = this.articulos
                }else{
                    let buscar = this.busqueda.toLowerCase()
                    this.articulosFiltrados = this.articulos.filter(function(item){
                        if(item.DESC_CLIENTE){
                            return (item.DESC_CLIENTE.toLowerCase().includes(buscar))
                        }else if(item.DESCRIPCION){
                            return (item.DESCRIPCION.toLowerCase().includes(buscar))
                        }else{
                            return false
                        }
                    }) 
                    // this.articulosFiltrados = this.articulos.filter((item) => (item.DESC_CLIENTE.toLowerCase().includes(this.busqueda.toLowerCase())))
                }
            }else{
                this.cargando = true
            }
        },
    }
}
</script>
<style scoped>
    .centered-input >>> input {
      text-align: center;
      text-align-last: center;
    }
    .cant.v-text-field--outlined >>> fieldset {
    border-color: orange;
    border-width: 2px;
    height: 35px;
    align-self: start;
    margin-top: 6px
    }
     .fixed-bar {
    position: sticky;
    position: -webkit-sticky; /* for Safari */
    top: 0em;
    z-index: 2;
    }
    
</style>