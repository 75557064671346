<template>
    <div>
        <v-card v-if="datosArt" elevation="0">
            <v-toolbar color="#ef6b01" rounded="0" elevation="5" class="fixed-bar">
                <v-btn icon dark @click="atras"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
                <v-card-title style="color:#ffff">Detalles del producto</v-card-title>
            </v-toolbar>
            <div :style="(artComp.length>0)?'margin-inline: 15px;margin-bottom:140px':'margin-inline: 15px;margin-bottom: 25px;'" class="mt-5">
                <p><b>{{(datosArt.DESC_CLIENTE)?datosArt.DESC_CLIENTE:datosArt.DESCRIPCION}}</b> </p>
                <div v-if="this.imagenes.length>=0" style="justify-content: center; display: grid;" >
                    <v-carousel class="caroucel" height="200" hide-delimiter-background hide-delimiters :show-arrows="(this.imagenes.length>1)?true:false" style="max-width: 500px;">
                        <v-img v-if="datosArt.etiqueta" style="position:absolute; z-index: 1; max-width: 100px; max-height: 30px;" contain :src="datosArt.etiqueta"/>
                        <v-carousel-item
                        height="auto"
                        v-for="(item,i) in this.imagenes"
                        :key="i"
                        :src="item.url"
                        >
                            <div v-if="(item.tipo!='VIDEO')" class="d-flex flex-column justify-end align-center fill-height">
                                <v-btn large icon color="orange" @click="verImagen(item.url)"><v-icon>mdi-magnify-plus-outline</v-icon></v-btn>
                            </div>
                            <iframe v-if="(item.tipo=='VIDEO')" :src="item.url"  frameborder="0" width="100%" height="100%" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                        </v-carousel-item>
                    </v-carousel>
                </div>
                <v-divider class="orange" style="border-width: 1px" dark></v-divider>
                <div class="mt-5">
                    <v-simple-table dense>
                        <tbody>
                            <tr>
                                <td style="font-size:18px; font-weight: bold;">Código</td>
                                <td style="font-weight: bold; color:#ef6b01;font-size:17px">{{datosArt.COD_ART}}</td>
                            </tr>
                            <tr>
                                <td style="font-size:18px; font-weight: bold;">EAN</td>
                                <td style="font-weight: bold; color:#ef6b01; font-size:17px">{{datosArt.EAN}}</td>
                            </tr>
                            <tr v-if="usuario.muestra_lista==1">
                                <td style="font-size:18px; font-weight: bold;">Precio contado</td>
                                <td style="font-weight: bold; color:#ef6b01; font-size:17px">{{precioArt() | toCurrency}}<span v-if="precioConIva" style="color:#ef6b01;font-size: 13px;font-weight: 400;">{{` más IVA (${porcentajeIva()}%)`}}</span></td>
                            </tr>
                            <tr>
                                <td style="font-size:18px; font-weight: bold;">Empaque</td>
                                <td style="font-weight: bold; color:#ef6b01; font-size:17px">{{(datosArt.ESCALA!=datosArt.ESC)?datosArt.ESCALA:''}} {{datosArt.CANT_EMPAQ}} {{datosArt.ESC}}</td>
                            </tr>
                            <tr v-if="datosArt.CANT_EMPAQ!=1 && usuario.muestra_lista==1">
                                <td style="font-size:18px; font-weight: bold;">Precio por empaque contado</td>
                                <td style="font-weight: bold; color:#ef6b01; font-size:17px">{{precioPorEmpaque() | toCurrency}}<span v-if="precioConIva" style="color:#ef6b01;font-size: 13px;font-weight: 400;">{{` más IVA (${porcentajeIva()}%)`}}</span></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </div>
                <v-divider class="orange mb-2" style="border-width: 1px" dark></v-divider>
                <div style="justify-content: end; display: grid;">
                    <v-btn v-if="(carrito.findIndex(i => i.cod_art==datosArt.COD_ART)==-1)" color="orange" @click="agregar()" elevation="0" outlined dark style="max-width: 115px;"><font-awesome-icon style="font-size:22px" icon="cart-shopping"/>{{(usuario.muestra_lista==1)?'Agregar':'Cotizar'}}</v-btn>
                    <template v-if="(carrito.findIndex(i => i.cod_art==datosArt.COD_ART)!=-1)">
                        <p style="color:green;text-align: center;margin: 0px;" class="mt-2"><v-icon style="font-size:20px" color="green">mdi-cart-check</v-icon>Agregado al carrito</p>
                        <v-row class="mt-3" style="margin-right: auto;">
                            <v-btn color="orange" fab icon small @click="cantidadMenos(datosArt)"><v-icon>mdi-minus-thick</v-icon></v-btn>
                            <v-text-field
                            v-model="carrito[carrito.findIndex(i => i.cod_art==datosArt.COD_ART)].cantidad"
                            label="Cantidad" type="number"
                            dense flat solo readonly
                            color="orange" style="max-width: 97px;align-items: center;" class="cant centered-input text-orange"
                            @click="clicCant"
                            ></v-text-field>
                            <v-btn color="orange" fab icon small @click="cantidadMas(datosArt)"><v-icon>mdi-plus-thick</v-icon></v-btn>
                        </v-row>
                        <p style="color:orange;text-align: center;max-width: 168px;">{{descEmpaque(datosArt)}}</p>
                    </template>
                </div>
                    
                <h2 class="mt-5">Descripción</h2>
                <v-textarea v-if="datosArt.NOTA!=null" v-model="datosArt.NOTA" filled full-width auto-grow class="mt-5" style="font-size: large;" outlined readonly color="orange" />
            </div>
            <v-footer padless fixed class="mt-5" rounded="0">
                <v-card class="flex" dense flat tile>
                    <v-card-title class="white text-center justify-center" style="padding: 0px;">
                        <v-sheet class="mx-auto" elevation="0" style="max-width: 100vw" v-if="artComp.length>0">
                            <v-slide-group mobile-breakpoint="600"  show-arrows="desktop" style="margin-bottom:8px">
                                <v-slide-item v-for="(item,index) in artComp" :key="index" class="mx-2" v-slot="{ active }">
                                    <!-- <v-img :src="item.recurso" height="80"  width="80" style="border: ridge;"></v-img> -->
                                    <v-card elevation="5"  :input-value="active" @click="clicArtComp(item)">
                                            <v-img :src="`${item.imagen}`" height="80"  width="80" :key="componentKey"  @error="onErrorImg(item)"></v-img>
                                    </v-card>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                    </v-card-title>

                    <v-card-text class="py-2 text-end justify-end" style="background-color:#ef6b01;height: 44px;padding: 0px;">
                        <v-row justify="center" no-gutters>
                            <v-col></v-col>
                            <v-col class="text-center">
                                <v-btn style="bottom: 31px;" fab color="#ef6b01" elevation="20" @click="irHome" class="my-2"><font-awesome-icon style="font-size:25px;color: white" icon="plus"/></v-btn>
                            </v-col>
                            <v-col class="text-end">
                                <v-btn @click="irCarrito" icon class="my-1 mr-2" color="white" style="height: auto;"><font-awesome-icon style="font-size:22px;color: white;" icon="cart-shopping"/>{{(carrito.length>0)?carrito.length:''}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    </v-card>
                
            </v-footer>
            <!-- SNACKBAR -->
            <v-snackbar v-model="snackbar" class="mb-15" :color="snackbarColor" text outlined>
                {{ snackbarMsge }}
                <template v-slot:action="{ attrs }">
                    <v-btn icon :color="snackbarColor" text v-bind="attrs" @click="snackbar = false"><v-icon>{{snackbarIcon}}</v-icon></v-btn>
                </template>
            </v-snackbar>
        </v-card>
        <!-- DIALOG CANTIDAD -->
        <v-dialog v-model="dialogCantidad" max-width="300px" persistent>
            <v-card v-if="datosArt">
                <v-toolbar color="#ef6b01">
                    <v-btn icon dark @click="cerrarDialogCambiarCantidad"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
                    <v-card-title style="color:#ffff">Cambiar cantidad</v-card-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="mt-3" v-if="(carrito.findIndex(i => i.cod_art==datosArt.COD_ART)>=0)">
                    <v-text-field autofocus outlined style="font-size: 20px;" v-model="carrito[carrito.findIndex(i => i.cod_art==datosArt.COD_ART)].cantidad" label="Cantidad" type="number" solo color="orange"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="orange" elevation="5" outlined @click="cambiarCantidad()" :disabled="!validarCantidad">Aceptar</v-btn>
                    <v-btn color="grey" elevation="5" @click="cerrarDialogCambiarCantidad" outlined>Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- DIALOG VER IMAGEN  -->
        <v-dialog v-model="dialogVerImagen" v-if="imagenSelected" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar color="#ef6b01">
                    <v-btn icon dark @click="dialogVerImagen = false"><v-icon>mdi-arrow-left</v-icon></v-btn>
                </v-toolbar>
                <v-card-actions class="d-flex justify-end" style="padding-bottom:0px">
                    <v-btn icon @click="dialogVerImagen = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-actions>
                <ImagenZoom :imagen="imagenSelected" v-if="dialogVerImagen"/>
                <v-footer color="#ef6b01" padless fixed class="mt-5" style="height: 30px;" rounded="0"></v-footer>
            </v-card>
        </v-dialog>
        
        <!-- ----CARGANDO------- -->
        <v-overlay :value="cargando">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import ApiServer from '../api';
import Imagen from '../components/Imagen.vue'
import ImagenZoom from '../components/ImagenZoom.vue';
import {mapActions, mapState} from 'vuex'
export default {
    name:'Articulo',
    components:{Imagen,ImagenZoom},
    props:{datosArticulo: {type: Object}},
    data(){
        return{
            datosArt:[],
            imagenes:[],
            artComp:[],
            menuOtrasOpciones:false,
            snackbar:false,
            snackbarMsge:'',
            snackbarColor:'error',
            snackbarIcon:'mdi-close-circle',
            dialogCantidad:false,
            cargando:false,
            componentKey:0,
            imagenSelected: null,
            dialogVerImagen: false,
        }
    },
    methods:{
        ...mapActions(['editarLista','agregarAlCarrito','editarCantCarrito','borrarArtCarrito']),
        async agregar(){
            this.cargando = true
            try {
                let result = await ApiServer.postCarritoCliente({
                    cod_cli:this.usuario.cod_cli,
                    cod_art:this.datosArt.COD_ART,
                    cantidad: 1,
                    lpr_id:this.datosArt.LISTA_ID,
                    usuario: this.usuario.nombreUsuario,
                    accion:'CARRITO'
                });
                this.agregarAlCarrito({
                    id:result.insertId,
                    lpr_id:this.datosArt.LISTA_ID,
                    cod_art:this.datosArt.COD_ART,
                    art_id:this.datosArt.ART_ID,
                    desc_cliente: this.datosArt.DESC_CLIENTE,
                    descripcion: this.datosArt.DESCRIPCION,
                    mod:this.datosArt.MOD,
                    med:this.datosArt.MED,
                    cantidad:1,
                    escala_id:this.datosArt.ESCALA_ID,
                    escala:this.datosArt.ESCALA,
                    esc:this.datosArt.ESC,
                    esc_plu:this.datosArt.ESC_PLU,
                    pr_vta:this.datosArt.PR_VTA,
                    pr_cto_cpra:this.datosArt.PR_CTO_CPRA,
                    pr_cto:this.datosArt.PR_CTO,
                    pr_final:this.datosArt.PR_FINAL,
                    descuento:this.datosArt.DESCUENTO,
                    disponible:this.datosArt.DISPONIBLE,
                    cant_empaq:this.datosArt.CANT_EMPAQ,
                    ord_rec_str:this.datosArt.ORD_REC_STR,
                    ean:this.datosArt.EAN,
                    imagen : `https://loguin.com.ar/img/${this.datosArt.ART_ID}.jpg`,
                    etiqueta : this.datosArt.etiqueta
                })
                this.cargando = false
            } catch (error) {
                console.log(error);
                this.cargando = false
            }
        },
        async cantidadMas(item){
            this.cargando = true
            try {
                let index = this.carrito.findIndex(i => i.cod_art==item.COD_ART)
                if((this.carrito[index].cantidad*this.carrito[index].cant_empaq)<item.DISPONIBLE){
                    let cant = this.carrito[index].cantidad+1
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                    this.cargando = false
                }else{
                    this.cargando = false
                    this.snackbar = true
                    this.snackbarColor ='error'
                    this.snackbarIcon = 'mdi-close-circle'
                    this.snackbarMsge='Sin stock'
                }
                
            } catch (error) {
                console.log(error);
                this.cargando = false
            }
        },
        async cantidadMenos(item){
            this.cargando = true
            try {
                let index = this.carrito.findIndex(i => i.cod_art==item.COD_ART)
                if(index>=0){
                    if(this.carrito[index].cantidad>1){
                        let cant = this.carrito[index].cantidad-1
                        await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                        this.editarCantCarrito({index:index,cantidad:cant})
                    }else{
                        await ApiServer.deleteCarritoCliente(this.carrito[index].id)
                        this.borrarArtCarrito(this.carrito[index].id)
                    }
                }
                this.cargando = false
            } catch (error) {
                 console.log(error);
                this.cargando = false
            }
        },
        clicCant(){
            this.dialogCantidad = true
        },
        async cambiarCantidad(){
            this.dialogCantidad = false
            this.cargando = true
            try {
                let index = this.carrito.findIndex(i => i.cod_art==this.datosArt.COD_ART)
                let cant = this.carrito[index].cantidad
                if(cant>0 && (cant <= this.datosArt.DISPONIBLE)){
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                }
                this.cargando = false
                
            } catch (error) {
                this.cargando = false
                this.dialogCantidad = false
                console.log(error);
            }
        },
        atras(){
            this.$router.go(-1)
        },
        irCarrito(){
            this.$router.push({name: 'Carrito'});
        },
        irHome(){
            this.$router.push({name: 'Home'});
        },
        async clicArtComp(item){
            try {
                let result = await ApiServer.getArtLpr(item.COD_ART,this.datosArt.LISTA_ID,this.usuario.cli_id)
                console.log('resultLpr',result);
                if(result.length>0){
                    this.artComp = []
                    this.$router.push({name: 'Articulo', params: { codArt: item.COD_ART,datosArt:result[0] }});

                }
            } catch (error) {
                console.log(error);
            }
        },
        async cargar(){
            this.cargando = true
            if(!this.$route.params.codArt){
                this.$router.push({name: 'Home'});
            }else{
                if(!this.datosArticulo){
                    //BUSCAR EL ARTICULO
                    let lista = null
                    if(this.listaConsultada){
                        lista = this.listaConsultada
                    }else{
                        lista = this.usuario.listas[0].LISTA_ID
                    }
                    let resultArt = await ApiServer.getArtLpr(this.$route.params.codArt,lista,this.usuario.cli_id)
                    if(resultArt.length==0){
                        this.$router.go(-1)
                    }else{
                        if(resultArt.length>0 && resultArt[0].CANT_EMPAQ==0){
                            resultArt[0].CANT_EMPAQ = 1
                        }
                        this.datosArt = resultArt[0]
                        //SI TIENE DESCUENTO
                        // if(this.datosArt.DESCUENTO && this.datosArt.DESCUENTO>0){
                        //     this.datosArt.PR_FINAL = this.datosArt.PR_FINAL - this.datosArt.PR_FINAL * (this.datosArt.DESCUENTO/100)
                        //     this.datosArt.PR_VTA = this.datosArt.PR_VTA - this.datosArt.PR_VTA * (this.datosArt.DESCUENTO/100)
                        // }
                        let resultEtiqueta = await ApiServer.getEtiquetaProducto(this.$route.params.codArt)
                        this.datosArt.etiqueta = null
                        if(resultEtiqueta.length>0)
                            this.datosArt.etiqueta = `https://loguin.com.ar/img/imag_etiquetas/${resultEtiqueta[0].etiqueta}?t=${(new Date()).getTime()}`
                    }
                }else{
                    this.datosArt = this.datosArticulo
                }
                this.editarLista(this.datosArt.LISTA_ID)
                
                let resultImagenes = await ApiServer.getImagenesArt(this.datosArt.ART_ID)
                this.imagenes=[]
                if(resultImagenes.length>0){
                    resultImagenes.forEach(item => {
                        this.imagenes.push({url:item.url,tipo:item.tipo})
                    });
                }else{
                    this.imagenes.push({url:`https://www.loguin.com.ar/img/${this.datosArt.ART_ID}.jpg?t=${(new Date()).getTime()}`,tipo:'IMAGEN'})
                }
                this.cargando = false
                try {
                    let resultArtRel = await ApiServer.getArtRel(this.datosArt.COD_ART)
                    
                    for(const item of resultArtRel){
                        item.imagen=`https://www.loguin.com.ar/img/${item.ART_ID}.jpg`
                    }
                    this.artComp = resultArtRel
                } catch (error) {
                    console.log(error);
                }

                await ApiServer.postUsoLog({
                        cod_cli: this.usuario.cod_cli,
                        usuario: this.usuario.nombreUsuario,
                        accion: 'ARTICULO',
                        dato: this.datosArt.COD_ART
                    })
            }
        },
        clickImagen(item){
            console.log(item);
        },
        onErrorImg(item){
            item.imagen = require('../assets/default_image.png')
            this.componentKey++//PARA ACTUALIZAR
            
        },
        descEmpaque(item){
            let cant = this.carrito[this.carrito.findIndex(i => i.cod_art==item.COD_ART)].cantidad
            if(cant==1){
                if(item.ESCALA != item.ESC){
                    return `${cant} ${item.ESCALA} / ${cant*item.CANT_EMPAQ} ${item.ESC}`
                }else{
                    return `${cant} ${item.ESCALA}`
                }
            }else{
                if(item.ESCALA != item.ESC){
                    return `${cant} ${item.ESC_PLU} / ${cant*item.CANT_EMPAQ} ${item.ESC}`
                }else{
                    return `${cant} ${item.ESC_PLU}`
                }
            }
        },
        cerrarDialogCambiarCantidad(){
            let index = this.carrito.findIndex(i => i.cod_art==this.datosArt.COD_ART)
            let cant = this.carrito[index].cantidad*this.carrito[index].cant_empaq
            if(cant>=this.datosArt.DISPONIBLE){
                this.carrito[index].cantidad=this.datosArt.DISPONIBLE/this.datosArt.CANT_EMPAQ   
            }
            if(cant<=0){
                this.carrito[index].cantidad = 1
            }
            this.dialogCantidad = false
        },
        porcentajeIva(){
            let x = this.datosArt.PR_FINAL/this.datosArt.PR_VTA
            if(Math.abs(21-(x-1)*100)<Math.abs(10.5-(x-1)*100)){
                return 21
            }else{
                return 10.5
            }
        },
        precioArt(){
            let precio = null
            if(this.precioConIva){
                precio = this.datosArt.PR_VTA + this.datosArt.PR_VTA*(this.datosArt.DESCUENTO/100)
            }else{
                precio = this.datosArt.PR_FINAL + this.datosArt.PR_FINAL*(this.datosArt.DESCUENTO/100)
            }
            return Math.round(precio)
        },
        precioPorEmpaque(){
            let precio = null
            if(this.precioConIva){
                let pr_vta= this.datosArt.PR_VTA + this.datosArt.PR_VTA*(this.datosArt.DESCUENTO/100)
                precio = Math.round(pr_vta)*this.datosArt.CANT_EMPAQ
            }else{
                let pr_final = this.datosArt.PR_FINAL + this.datosArt.PR_FINAL*(this.datosArt.DESCUENTO/100)
                precio = Math.round(pr_final)*this.datosArt.CANT_EMPAQ
            }
            return precio
        },
        verImagen(imagen){
            this.imagenSelected = imagen
            this.dialogVerImagen = true
        },
    },
    computed:{
         ...mapState(['listaConsultada','usuario','carrito']),
         validarCantidad(){
             let index = this.carrito.findIndex(i => i.cod_art==this.datosArt.COD_ART)
             if(index>=0){
                let cant = this.carrito[index].cantidad*this.carrito[index].cant_empaq
                if(cant>this.datosArt.DISPONIBLE || cant<=0){
                    return false
                }else{
                    return true
                }
            }else{
                return false
            }
         },
        precioConIva(){
            if(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI'){
                return true
            }else{
                return false
            }
        },
        
    },
   
    mounted(){
        this.cargar()
    },
    created() {
        this.$watch(() => this.$route.params,
        (toParams, previousParams) => {
            this.cargar()
        }
        )
    },
}
</script>

<style scoped>
    .row-pointer >>> tbody tr :hover {
    cursor: pointer;
    }
    .caroucel >>> .v-image__image--cover{
        background-size: contain
    }
    .centered-input >>> input {
      text-align: center
    }
    .cant.v-text-field--outlined >>> fieldset {
    border-color: orange;
    border-width: 2px;
    height: 35px;
    align-self: start;
    margin-top: 6px
    }
    .fixed-bar {
        position: sticky;
        position: -webkit-sticky; /* for Safari */
        top: 0em;
        z-index: 2;
    }
    .caroucel >>> .v-window__next,
    .caroucel >>> .v-window__prev {
        background: #ff9800 !important; /* Cambia el color de las flechas */
    }
</style>