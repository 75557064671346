import axios from 'axios'
import Cookies from 'js-cookie'

export default{
    //------- USUARIOS------------------
    async login(datos){
        return (await axios.post(`./api/usuarios/login`,datos)).data
    },
    async getBotonesInferiores(cod_cli){
        try {
            return (await axios.get(`./api/usuarios/botones/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getDatosCliente(cod_cli){
        try {
            return (await axios.get(`./api/usuarios/datoscliente/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getPuntosCliente(cod_cli){
        try {
            return (await axios.get(`./api/usuarios/puntos/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async putPuntosCliente(datos){
        try {
            return (await axios.put(`./api/usuarios/puntos`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async postRegistroPuntos(datos){
        try {
            return (await axios.post(`./api/usuarios/registropuntos`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getFleteCliente(cod_cli){
        try {
            return (await axios.get(`./api/usuarios/fletecliente/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getClientesAsociados(cod_cli){
        try {
            return (await axios.get(`./api/usuarios/clientes/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getAplicacionUsuario(id){
        try {
            return (await axios.get(`./api/usuarios/aplicacion/${id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async postUsoLog(datos){
        try {
            return (await axios.post(`./api/usuarios/log`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getDescuento(cod_cli,cod_art){
        try {
            return (await axios.get(`./api/usuarios/descuento/${cod_cli}/${cod_art}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    // -------ARTICULOS-------------------
    async getArtRubrosHijos(rubro_padre_id,lista_id){
        try {
            return (await axios.get(`./api/articulos/rubroshijos/${rubro_padre_id}/${lista_id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getArticulosPorRubro(rubro_id,lista_id,cli_id){
        try {
            return (await axios.get(`./api/articulos/rubro/${rubro_id}/${lista_id}/${cli_id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getListaPreciosCliente(cli_id){
        try {
            return (await axios.get(`./api/usuarios/listasprecios/${cli_id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getImagenRubro(rubro_id){
        try {
            return (await axios.get(`./api/articulos/artrubro/imagen/${rubro_id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getArtRel(cod_art){
        try {
            return (await axios.get(`./api/articulos/complementarios/${cod_art}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getArtLpr(cod_art,lpr_id,cli_id){
        try {
            return (await axios.get(`./api/articulos/lpr/${cod_art}/${lpr_id}/${cli_id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getBuscarArt(busqueda,lista_id,cli_id){
        try {
            return (await axios.get(`./api/articulos/buscar/${lista_id}/${cli_id}?busqueda=${busqueda}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getImagenesArt(art_id){
        try {
            return (await axios.get(`./api/articulos/imagenes/${art_id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getListaArticulos(cod_cli,agrupador){
        try {
            return (await axios.get(`./api/articulos/listaarticulos/${agrupador}/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getCheckPrecioStock(cod_art,lpr_id,cli_id){
        try {
            return (await axios.get(`./api/articulos/preciostock/${cod_art}/${lpr_id}/${cli_id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getArtCatalogo(){
        try {
            return (await axios.get(`./api/articulos/catalogo`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getStkID(art_id){
        try {
            return (await axios.get(`./api/articulos/stockid/${art_id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async postStock(datos){
        try {
            return (await axios.post(`./api/articulos/stock`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async postStockMov(datos){
        try {
            return (await axios.post(`./api/articulos/stockmov`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getOferta(cod_art){
        try {
            return (await axios.get(`./api/articulos/oferta/${cod_art}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async getProductosPorCotizacion(cod_cli){
        try {
            return (await axios.get(`./api/articulos/productos_por_cotizacion/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    // ---------CARRITO-----------------------

    async getCarritoCliente(cod_cli){
        try {
            return (await axios.get(`./api/articulos/carrito/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async postCarritoCliente(datos){
        try {
            return (await axios.post(`./api/articulos/carrito`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async putCarritoCliente(datos){
        try {
            return (await axios.put(`./api/articulos/carrito`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async deleteCarritoCliente(id){
        try {
            return (await axios.delete(`./api/articulos/carrito/${id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async deleteVaciarCarrito(cod_cli){
        try {
            return (await axios.delete(`./api/articulos/carrito/borrar/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async postCarritoLog(datos){
        try {
            return (await axios.post(`./api/usuarios/carritolog`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    //---------RECURSOS------------------------
    // async getRecursos(bucket_id){
    //     // return (await axios.get(`https://cms.bbjetcloud.com/recursos/api/buckets/${bucket_id}/resources`)).data
    //     return (await axios.get(`http://bbjet.ferrenet.net/recursos/api/buckets/${bucket_id}/resources`)).data
    // },

    // -------TRANSPORTES--------------
    async getTransportesCliente(cod_cli){
        try {
            return (await axios.get(`./api/transportes/cliente/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    // ----------COMPROBANTES--------------
    async getTalonarios(tal_id){
        try {
            return (await axios.get(`./api/comprobantes/talonarios/${tal_id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async putTalonarios(tal_id){
        try {
            return (await axios.put(`./api/comprobantes/talonarios/${tal_id}`,{},{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async getCprIdProcedure(){
        try {
            return (await axios.get(`./api/comprobantes/cprid/procedure`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async postCpr(datos){
        try {
            return (await axios.post(`./api/comprobantes/cpr`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async postCprDet(datos){
        try {
            return (await axios.post(`./api/comprobantes/cprdet`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async postCprdetImp(datos){
        try {
            return (await axios.post(`./api/comprobantes/cprdetimp`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async postCprpie(datos){
        try {
            return (await axios.post(`./api/comprobantes/cprpie`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async postCprCpr(datos){
        try {
            return (await axios.post(`./api/comprobantes/cprcpr`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async putCprMonto(cpr_id,datos){
        try {
            return (await axios.put(`./api/comprobantes/cprmonto/${cpr_id}`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async putCprEstado(cpr_id,body){
        try {
            return (await axios.put(`./api/comprobantes/cprestado3/${cpr_id}`,body,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async getPedidosCliente(cod_cli){
        try {
            return (await axios.get(`./api/comprobantes/cprcliente/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async getImagenesCprEstados(){
        try {
            return (await axios.get(`./api/comprobantes/estados/imagenes`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async getDetallePedido(cpr_id){
        try {
            return (await axios.get(`./api/comprobantes/detallecpr/${cpr_id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async postLogisticaCprDatos(datos){
        try {
            return (await axios.post(`./api/comprobantes/logisticacprdatos`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async postCprDetCprDet(datos){
        try {
            return (await axios.post(`./api/comprobantes/cprdetcprdet`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async getCanjesCliente(cod_cli){
        try {
            return (await axios.get(`./api/comprobantes/canjes/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async getPresupuestoCliente(cod_cli){
        try {
            return (await axios.get(`./api/comprobantes/presupuestos/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async getCprDet(cpr_id,cod_art){
        try {
            return (await axios.get(`./api/comprobantes/cprdet/${cpr_id}/${cod_art}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async putCprdetCantNoProc(datos){
        try {
            return (await axios.put(`./api/comprobantes/cprdetcantnoproc`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    // -----VARIOS-----
    async getHorariosRetiro(){
        try {
            return (await axios.get(`./api/varios/horariosretiro`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async getEtiquetasProductos(){
        try {
            return (await axios.get(`./api/varios/etiquetasproducto`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }

    },
    async getEtiquetaProducto(cod_art){
        try {
            return (await axios.get(`./api/varios/etiquetaproducto/${cod_art}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async getPantallaInicial(cod_cli){
        try {
            return (await axios.get(`./api/varios/pantallainicial/${cod_cli}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async putPantallaInicial(cod_cli){
        try {
            return (await axios.put(`./api/varios/pantallainicial/${cod_cli}`,{},{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async postPantallaInicialLog(datos){
        try {
            return (await axios.post(`./api/varios/pantallainiciallog`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async putDatosNavegador(datos){
        try {
            return (await axios.put(`./api/varios/datosnavegador`,datos,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },
    async getDatosNavegador(id){
        try {
            return (await axios.get(`./api/varios/datosnavegador/${id}`,{ headers: {"Authorization" : `Bearer ${Cookies.get('token')}`} })).data
        } catch (error) {
            if(error.response && error.response.data && error.response.data.message=='Token expired'){
                window.location.href = "https://loguin.com.ar/?token_expired=true"
            }else{
                console.log(error);
                throw new Error(error)
            }
        }
    },

    async postLogAcceso(datos){
        return (await axios.post(`./api/varios/logacceso`,datos)).data
    }


}